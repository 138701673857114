import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'  //导入 页面组件
import 'element-plus/dist/index.css'    //导入 页面样式
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'   //导入 支持中文
import '@/assets/css/global.css'        //导入 样式
import elementIcon from './plus/icons'  //导入 图标


const app = createApp(App)

app.use(store)          //状态管理
app.use(router)         //路由
app.use(ElementPlus, {locale: zhCn,size:'small'})   //页面组件 支持中文
app.use(elementIcon)    //图标

app.mount('#app')

