import { createStore } from 'vuex'

export default createStore({
  state: {
    // counter:0
  },
  getters: {
    // getCounter(state){
    //   return state.counter >=0 ? state.counter : "网络异常"
    // },
  },
  mutations: {
    // addCounter (state,num) {
    //   state.counter = num
    // }
  },
  actions: {
  },
  modules: {
  }
})
