<template>
    <div style="height: 50px;line-height: 50px;border-bottom: 1px solid #ccc;display: flex;">

        <div style="width: 200px;padding-left: 30px;font-weight: bold;font-size: 20px;color: blue">云记事本</div>

        <div style="flex: 1;"></div>

        <div style="width: 100px;">
            <el-dropdown style="font-size: 20px;margin-top: 13px;">
                <span class="el-dropdown-link">
                    {{ user }}<el-icon class="el-icon--right"><arrow-down /></el-icon>
                </span>
                <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item style="font-size: 15px;margin-top: 5px;">个人信息</el-dropdown-item>
                    <el-dropdown-item @click="logout" style="font-size: 15px;margin-top: 5px;margin-bottom: 5px;">退出系统</el-dropdown-item>
                </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div>

    </div>
</template>



<script>

    import axios from 'axios'

    export default {
        name: "Header",
        data() {
            return {
                user:''
            }
        },
        mounted(){
            axios({
                method:"GET",
                headers:{'token': JSON.parse(window.localStorage.getItem('token'))},
                url:"/api/user/app"
            }).then(res => {
                //console.log("头部 调试");
                //console.log(res);
                this.user = res.data.data.name
            },error => {
                console.log("头部 出错了");
                console.log(error);
            })
        },

        //方法区域
        methods:{
            logout(){
                axios({
                    method:"GET",
                    headers:{'token': JSON.parse(window.localStorage.getItem('token'))},
                    url:"/api/user/logout"
                }).then(res => {
                    //console.log("退出登录 调试");
                    //console.log(res);
                    if(res.data.code==200){
                        localStorage.removeItem("token")  // 删除token
                        this.$message({
                            type:"success",
                            message:res.data.msg
                        })
                        this.$router.push('/login')  //路由到 登录界面
                    }else{
                        this.$message({
                            type:"error",
                            message:res.data.msg
                        })
                    }
                },error => {
                    if(error.response.status==403){
                        this.$message({
                            type:"error",
                            message:'出错了'
                        })
                    }
                })
            }
        }

    }

</script>

<style scoped>

</style>



