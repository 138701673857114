import { createRouter, createWebHistory } from 'vue-router' //导入 路由
import LayoutView from '../layout/Layout.vue'               //导入主界面

const routes = [
  {
    path: '/',
    name: 'layout',
    component: LayoutView,//路由到 layout

    children:[            //二级 菜单 路由
      {
        path:"/text",
        name: 'text',
        component: () => import('../views/Text.vue'),   //路由到 layout/text
      },
      {
        path:"/file",
        name: 'file',
        component: () => import('../views/File.vue'),   //路由到 layout/file
      },
      {
        path:"/user",
        name: 'user',
        component: () => import('../views/User.vue'),   //路由到 layout/user
      },
    ],

  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')     //路由到 login
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register.vue')  //路由到 register
  },
  {
    path: '/zhaohui',
    name: 'zhaohui',
    component: () => import('../views/Zhaohui.vue')  //路由到 zhaohui
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

