<template>
    <div>
        <!--  头部  -->
        <Header></Header>
        <!--  主体  -->
        <div style="display: flex">
            <!--  侧边栏    -->
            <Aside></Aside>
            <!--   内容区域   -->
            <router-view style="flex: 1" />
        </div>
    </div>
</template>


<script>

  import Header from "../components/Header";//导入 头部 组件
  import Aside from "../components/Aside";  //导入 侧边栏 组件

  export default {
    name:"Layout",
    components:{
      Header, //加入 头部 组件
      Aside   //加入 侧边栏 组件
    },

    // mounted(){
    //   if(this._isMobile()){
    //     console.log('手机端');
    //     alert('手机端')
    //   }else{
    //     console.log('电脑端');
    //     alert('电脑端')
    //   }
    // }, 
    // methods:{
    //   _isMobile(){
    //     let flag9 = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    //     return flag9
    //   }
    // }
  }

</script>

<style>

</style>






