<template>
    <div>
        <el-menu style="width: 200px;min-height: calc(100vh-50px);" 
        :default-active="path" 
        router
        :default-openeds=[1] 
        class="el-menu-vertical-demo" >
                                  
            <el-menu-item index="/file">
               <el-icon><document /></el-icon>
               <span>文件管理</span>
            </el-menu-item>

            <!-- <el-menu-item index="/image">
               <el-icon><document /></el-icon>
               <span>图片管理</span>
            </el-menu-item> -->

           <el-menu-item index="/text">
               <el-icon><document /></el-icon>
               <span>文档管理</span>
           </el-menu-item>

           <el-menu-item v-if="xianshi" index="/user">
               <el-icon><document /></el-icon>
               <span>用户管理</span>
            </el-menu-item>

        </el-menu>
    </div>
</template>

<script>

    import axios from 'axios'

    export default {
        name: "Aside",
        data(){
            return{
                path:this.$route.path,
                xianshi:0
            }
        },
        
        //钩子函数
        created() {
            if(!window.localStorage.getItem('token')){  //如果token为空
                this.$router.push('/login')   //路由到 /login
            }else{                          //否则 发送token到服务器 验证身份
                axios({
                    method:"GET",
                    headers:{'token': JSON.parse(window.localStorage.getItem('token'))},
                    url:"/api/user/app"
                }).then(res => {
                    //console.log("侧边栏 调试");
                    //console.log(res);
                    if(res.data.code==401){
                        this.$router.push('/login')  //路由到 登录界面
                    }else if(res.data.code==200){
                        for(let i = 0; i < res.data.data.authorities.length; i++){
                            //console.log(res.data.data.authorities[i].authority);
                            if(res.data.data.authorities[i].authority === "admin:list"){
                                this.xianshi=1
                            }
                        }
                    }
                },error => {
                    console.log("侧边栏 出错了");
                    console.log(error);
                })
            }
        }
    }

</script>

<style scoped>

</style>





